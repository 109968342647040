<template>
  <div :class="$style.page">
    <div :class="$style.content">
      <slot>
        <!-- survey here -->
      </slot>
    </div>
  </div>
</template>

<style module>
.page {
  @apply vf-flex vf-fixed vf-left-0 vf-right-0 vf-top-0 vf-bottom-0 vf-border-none vf-w-full vf-h-full;
}

.content {
  @apply vf-flex vf-items-stretch vf-w-full;
}
</style>
