<template>
  <div :class="$style.page">
    <div class="vf-flex vf-flex-1">
      <slot></slot>
    </div>
  </div>
</template>

<style module>
.page {
  position: relative;
  display: flex;
  max-width: 100%;
  width: 100%;
  height: 100%;
}
</style>
