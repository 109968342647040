const defaultButtonProperties = {
  label: "Speak up",
  color: "#0066FF",
  style: "filled",
  size: "medium",
  borderRadius: null,
  fontSize: null,
  icon: null,
  iconOnly: false,
};

export default {
  height: "600px",
  width: "100%",
  button: {
    ...defaultButtonProperties,
  },
};
