<template>
  <ActionButton @click="open = true"></ActionButton>
  <TransitionRoot as="template" :show="open">
    <Dialog :unmount="false" as="div" :class="$style.dialog">
      <TransitionChild
        as="template"
        enter="ease-out vf-duration-300"
        enter-from="vf-opacity-0"
        enter-to="vf-opacity-100"
        leave="ease-in vf-duration-200"
        leave-from="vf-opacity-100"
        leave-to="vf-opacity-0"
      >
        <div :class="$style.backdrop" />
      </TransitionChild>

      <div :class="$style.modalLayout">
        <div :class="$style.modalContainer">
          <div :class="$style.closeButtonContainer">
            <button @click="open = false" :class="$style.closeButton">
              <XMarkIcon class="vf-h-6 vf-w-6" />
            </button>
          </div>
          <TransitionChild
            as="template"
            enter="ease-out vf-duration-300"
            enter-from="vf-opacity-0 vf-translate-y-4 sm:vf-translate-y-0 sm:vf-scale-95"
            enter-to="vf-opacity-100 vf-translate-y-0 sm:vf-scale-100"
            leave="ease-in vf-duration-200"
            leave-from="vf-opacity-100 vf-translate-y-0 sm:vf-scale-100"
            leave-to="vf-opacity-0 vf-translate-y-4 sm:vf-translate-y-0 sm:vf-scale-95"
          >
            <DialogPanel :class="$style.dialogPanel">
              <slot> <!-- survey here --> </slot>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/solid";
import ActionButton from "./ActionButton.vue";
const open = ref(false);
</script>

<style module>
.closeButtonContainer {
  @apply vf-flex vf-justify-end vf-w-full sm:vf-max-w-lg vf-my-2;
  -webkit-tap-highlight-color: transparent;
}
.closeButton {
  @apply vf-inline-flex vf-items-center vf-rounded-full vf-border vf-border-transparent vf-p-1 vf-text-black hover:vf-bg-gray-50 vf-cursor-pointer;
}
.dialog {
  @apply vf-relative vf-z-10;
}
.backdrop {
  @apply vf-fixed vf-inset-0 vf-bg-gray-500 vf-bg-opacity-75 vf-transition-opacity;
}
.modalLayout {
  @apply vf-flex vf-flex-col vf-fixed vf-inset-0 vf-z-10;
}
.modalContainer {
  @apply vf-flex vf-flex-col vf-min-h-full vf-items-end vf-justify-center vf-px-4 vf-text-center vf-items-center sm:vf-p-0;
}
.dialogPanel {
  @apply vf-relative vf-transform vf-overflow-hidden vf-rounded-lg vf-bg-white vf-text-left vf-shadow-xl vf-transition-all sm:vf-mb-8 vf-w-full vf-h-full sm:vf-max-w-lg;
  @media (min-width: 640px) {
    height: 600px;
  }
}
</style>
