if (!customElements.get("vf-widget")) {
  class VFWidget extends HTMLElement {
    constructor() {
      super();
    }

    connectedCallback() {
      this.style.display = "block"; // Ensure it behaves like a div inside connectedCallback
      // Poll until window.voiceform is available
      const pollForVoiceform = setInterval(() => {
        if (window.voiceform) {
          window.voiceform._loadWidget(this);
          clearInterval(pollForVoiceform); // Stop polling once window.voiceform is available
        } else {
          console.error("Voiceform instance is not available yet.");
        }
      }, 100); // Poll every 100ms
    }
  }

  customElements.define("vf-widget", VFWidget);
}
