import { createApp } from "vue";
import App from "./App.vue";
import { isValidMode } from "@/components/modeTypes";
import "./styles/app.css";
import defaultConfig from "./defaultConfig";
import initStore from "./store";
import "./vf-widget";

const currentScript = window.document.currentScript;

class VoiceformJS {
  // Method to initialize the SDK
  init() {
    if (
      process.env.NODE_ENV !== "production" ||
      currentScript.getAttribute("data-hash")
    ) {
      // if script tag has data attributes we want to init from script tag as well
      this._loadWidget(currentScript);
    }

    this._initAllSurveys();
  }

  // Method to send 'next' message to the iframe within the specified element
  next(element) {
    if (!element) {
      console.error("Element is required");
      return;
    }
    const iframe = element.querySelector("iframe");
    if (!iframe) {
      console.error("No iframe found in the element");
      return;
    }
    iframe.contentWindow.postMessage("voiceform.next", "*");
  }

  addWidget({
    mountTo = document.body,
    surveyHash,
    questionId,
    mode,
    ...rest
  }) {
    if (!surveyHash) {
      console.error("'surveyHash' is required to add a widget");
      return;
    }

    if (mode && !isValidMode(mode)) {
      console.error(
        "Invalid mode. Valid modes are: standard, full-page, popup, slider, popover, side-tab, single-question"
      );
      return;
    }

    if (mode === "single-question" && !questionId) {
      console.error("questionId is required for single-question mode");
      return;
    }

    let mountingElement = null;
    // check if mountTo is a string or an element
    if (typeof mountTo === "string") {
      mountingElement = document.getElementById(mountTo);
    }
    if (mountTo instanceof Element) {
      mountingElement = mountTo;
    }

    if (!mountingElement) {
      console.error(
        "Invalid mountTo parameter. Please provide a valid element or id."
      );
      return;
    }

    const container = document.createElement("div");
    container.setAttribute("class", "voiceform-container");
    container.setAttribute("data-hash", surveyHash);
    container.setAttribute("data-mode", mode || "standard");
    if (questionId) {
      container.setAttribute("data-question-id", questionId);
    }
    if (rest.containerId) {
      container.setAttribute("id", rest.containerId);
    }

    // Container properties
    if (rest.height) {
      container.setAttribute("data-vf-height", rest.height);
    }

    if (rest.width) {
      container.setAttribute("data-vf-width", rest.width);
    }

    if (rest.metadata) {
      container.setAttribute(
        "data-metadata",
        JSON.stringify(rest.metadata || {})
      );
    }

    if (rest.responseId) {
      container.setAttribute("data-response-id", rest.responseId);
    }

    if (rest.hideQuestion) {
      container.setAttribute("data-vf-hide-question", "true");
    }

    if (rest.hideNextButton) {
      container.setAttribute("data-vf-hide-next-button", "true");
    }

    // Button properties
    if (rest?.button?.label) {
      container.setAttribute("data-vf-btn-label", rest.label);
    }

    if (rest?.button?.color) {
      container.setAttribute("data-vf-btn-color", rest.color);
    }

    if (rest?.button?.style) {
      container.setAttribute("data-vf-btn-style", rest.style);
    }

    if (rest?.button?.size) {
      container.setAttribute("data-vf-btn-size", rest.size);
    }

    if (rest?.button?.borderRadius) {
      container.setAttribute("data-vf-btn-border-radius", rest.borderRadius);
    }

    if (rest?.button?.fontSize) {
      container.setAttribute("data-vf-btn-fonts-size", rest.fontSize);
    }

    if (rest?.button?.icon) {
      container.setAttribute("data-vf-btn-icon", rest.icon);
    }

    if (rest?.button?.iconOnly) {
      container.setAttribute("data-vf-btn-icon-only", rest.iconOnly);
    }

    mountingElement.appendChild(container);
    this._loadWidget(container);
  }

  // Static method to generate a unique hash
  static _generateUniqueHash() {
    return Math.random().toString(36).substr(2, 9);
  }

  // Private method to get attributes from an element
  _getAttributes(element) {
    return Array.from(element.attributes).reduce((acc, attr) => {
      if (attr.name.startsWith("data-")) {
        const name = attr.name;

        // Only parse JSON for specific attributes like data-metadata
        if (name === "data-metadata") {
          try {
            acc[name] = JSON.parse(attr.value);
          } catch (e) {
            console.error(`Failed to parse JSON for ${name}:`, e);
            acc[name] = {}; // Use an empty object for metadata
          }
        } else {
          acc[name] = attr.value; // Use the raw string for other attributes
        }

        // Apply default value if the attribute is missing
        if (!(name in acc)) {
          acc[name] = defaultConfig[name];
        }
      }
      return acc;
    }, {});
  }

  // Private method to set mode from an element
  _setMode(element) {
    let mode = element.getAttribute("data-mode");
    mode = mode ? mode.replace("vf-", "") : "standard";
    return isValidMode(mode) ? mode : "standard";
  }

  // Private method to create a container
  _createContainer(id) {
    const container = document.createElement("div");
    container.setAttribute("id", id);
    document.querySelector("body").appendChild(container);
    return container;
  }

  // Private method to mount the Vue app
  _mountApp(container, attributes, store) {
    const appContent = document.createElement("div");
    const uniqueHash = VoiceformJS._generateUniqueHash();

    let appContainerId = `voiceform-container__app_${attributes["data-hash"]}_${uniqueHash}`;

    if (attributes["data-question-id"]) {
      appContainerId = `voiceform-container__app_${attributes["data-hash"]}_${attributes["data-question-id"]}_${uniqueHash}`;
    }

    appContent.setAttribute("id", appContainerId);

    container.innerHTML = "";
    container.appendChild(appContent);

    if (attributes["data-mode"] === "standard") {
      if (attributes["data-vf-width"])
        container.style.width = attributes["data-vf-width"];
      if (attributes["data-vf-height"])
        container.style.height = attributes["data-vf-height"];
      appContent.style.width = "100%";
      appContent.style.height = "100%";
    }

    createApp(App, attributes).use(store).mount(`#${appContainerId}`);
  }

  // Private method to load the widget
  async _loadWidget(htmlElement) {
    const attributes = this._getAttributes(htmlElement);
    if (!attributes["data-hash"]) {
      throw new Error(
        "Survey Hash attribute is not set. The form cannot be loaded."
      );
    }

    // setting default width and height
    if (!attributes["data-vf-width"]) {
      attributes["data-vf-width"] = defaultConfig.width;
    }
    if (!attributes["data-vf-height"]) {
      attributes["data-vf-height"] = defaultConfig.height;
    }

    let mode = this._setMode(htmlElement);
    attributes["data-mode"] = mode;
    const store = initStore(htmlElement);

    let container = null;

    if (mode === "full-page") {
      document.querySelector("body").innerHTML = "";
      container = this._createContainer("voiceform-container");
      document.querySelector("body").appendChild(currentScript);
    } else if (htmlElement === currentScript) {
      // if htmlElement is an actual script tag, that means we want to mount the widget in the container with id "voiceform-container"
      container = document.getElementById("voiceform-container");
    } else {
      container = htmlElement;
    }

    this._mountApp(container, attributes, store);
  }

  // Private method to initialize all surveys
  _initAllSurveys() {
    document
      .querySelectorAll("#voiceform-container, .voiceform-container")
      .forEach((container) => this._loadWidget(container));
  }

  // Static method to apply settings
  static applySettings() {
    // Select all elements with id 'voiceform-container' and class 'voiceform-container'
    const containers = document.querySelectorAll(
      "#voiceform-container, .voiceform-container"
    );

    containers.forEach((container) => {
      if (container.id === "voiceform-container") {
        // Remove all attributes except the 'id'
        Array.from(container.attributes).forEach(
          (attr) => attr.name !== "id" && container.removeAttribute(attr.name)
        );
      }
      // Clear the inner content of the container
      container.innerHTML = "";
    });

    // Set current script attributes
    currentScript.setAttribute(
      "data-hash",
      document.getElementById("surveyHash").value
    );
    currentScript.setAttribute(
      "data-question-id",
      document.getElementById("questionId").value
    );
    currentScript.setAttribute(
      "data-mode",
      document.getElementById("mode").value
    );

    if (document.getElementById("hideQuestion").checked) {
      document
        .getElementById("single-question")
        ?.setAttribute("data-vf-hide-question", "true");
    } else {
      document
        .getElementById("single-question")
        ?.removeAttribute("data-vf-hide-question");
    }

    // Check if the 'Hide Next Button' checkbox is checked and set the attribute accordingly
    if (document.getElementById("hideNext").checked) {
      document
        .getElementById("single-question")
        .setAttribute("data-vf-hide-next-button", "true");
    } else {
      document
        .getElementById("single-question")
        .removeAttribute("data-vf-hide-next-button");
    }

    // Initialize the application
    new VoiceformJS().init();
  }
}

// Assign the SDK to the global window object
window.voiceform = new VoiceformJS();

if (process.env.NODE_ENV === "production") {
  window.voiceform.init();
} else {
  document
    .getElementById("applySettings")
    .addEventListener("click", VoiceformJS.applySettings);
}
