<template>
  <iframe
    :src="src"
    allow="microphone; camera"
    title="Voice powered form"
    :class="$style.surveyFrame"
  ></iframe>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
    };
  },
  props: ["src", "survey", "style"],
};
</script>

<style module>
.surveyFrame {
  display: flex;
  border: 0px;
  width: 100%;
  flex-grow: 1;
  height: 100%;
}
</style>
