<template>
  <ActionButton @click="open = true"></ActionButton>
  <SideDrawer v-model="open"><slot></slot></SideDrawer>
</template>

<script setup>
import { ref } from "vue";
import SideDrawer from "./SideDrawer.vue";
import ActionButton from "./ActionButton.vue";

const open = ref(false);
</script>
