export const STANDARD = "standard";
export const FULL_PAGE = "full-page";
export const POPUP = "popup";
export const SLIDER = "slider";
export const POPOVER = "popover";
export const SIDE_TAB = "side-tab";
export const SINGLE_QUESTION = "single-question";

export const MODE_LIST = [
  STANDARD,
  FULL_PAGE,
  POPUP,
  SLIDER,
  POPOVER,
  SIDE_TAB,
  SINGLE_QUESTION,
];

export const isValidMode = (mode) => {
  return MODE_LIST.includes(mode);
};
