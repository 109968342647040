// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PopoverMode_panel_YSO_5{--tw-bg-opacity:1;--tw-shadow:0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -2px rgba(0,0,0,.1);--tw-shadow-colored:0 4px 6px -1px var(--tw-shadow-color),0 2px 4px -2px var(--tw-shadow-color);background-color:rgb(249 250 251/var(--tw-bg-opacity));border-radius:12px;bottom:64px;box-shadow:var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);height:calc(100vh - 6rem);margin-left:24px;overflow:hidden;position:absolute;right:0;width:100%;width:calc(100vw - 2rem);z-index:10}@media (min-width:640px){.PopoverMode_panel_YSO_5{height:600px;width:370px}}.PopoverMode_closeButton_nCzgq{--tw-bg-opacity:1;--tw-text-opacity:1;--tw-shadow:0 20px 25px -5px rgba(0,0,0,.1),0 8px 10px -6px rgba(0,0,0,.1);--tw-shadow-colored:0 20px 25px -5px var(--tw-shadow-color),0 8px 10px -6px var(--tw-shadow-color);align-items:center;background-color:rgb(229 231 235/var(--tw-bg-opacity));border-radius:9999px;border-style:none;box-shadow:var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);color:rgb(0 0 0/var(--tw-text-opacity));cursor:pointer;display:inline-flex;height:40px;justify-content:center;transition-duration:.15s;transition-property:all;transition-timing-function:cubic-bezier(.4,0,.2,1);width:40px}.PopoverMode_closeButton_nCzgq:focus-within{outline:2px solid transparent;outline-offset:2px}.PopoverMode_closeButton_nCzgq:hover{background-color:var(--vf-btn-hover-color)!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": "PopoverMode_panel_YSO_5",
	"closeButton": "PopoverMode_closeButton_nCzgq"
};
export default ___CSS_LOADER_EXPORT___;
