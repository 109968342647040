<template>
  <div :class="$style.root" :style="style">
    <component :is="dataMode" :src="src" :questionId="dataQuestionId">
      <SurveyIframe :src="src" />
    </component>
  </div>
</template>

<script>
import Standard from "./components/StandardMode.vue";
import FullPage from "./components/FullPageMode.vue";
import Popup from "./components/PopupMode.vue";
import Slider from "./components/SliderMode.vue";
import Popover from "./components/PopoverMode.vue";
import SideTab from "./components/SideTabMode.vue";
import SurveyIframe from "./components/SurveyIframe.vue";
import SingleQuestion from "./components/SingleQuestion.vue";
import { SINGLE_QUESTION } from "./components/modeTypes";

export default {
  name: "App",
  components: {
    SideTab,
    Popover,
    Slider,
    Popup,
    FullPage,
    Standard,
    SurveyIframe,
    SingleQuestion,
  },
  props: {
    // predefined props
    dataMode: String,
    dataQuestionId: String,
    dataHash: String,
    dataMetadata: Object,
    dataResponseId: String,
    dataVfHideQuestion: String,
    dataVfHideNextButton: String,
    dataVfWidth: String,
    dataVfHeight: String,
  },
  computed: {
    customProps() {
      return this.$attrs; // This contains all the custom props passed to the component
    },
    src() {
      let url = `${process.env.VUE_APP_APP_URL}/to/${this.dataHash}`;

      if (this.dataQuestionId && this.dataMode === SINGLE_QUESTION) {
        url += `/questions/${this.dataQuestionId}`;
      }

      url += `?v-widget=${this.dataMode}`;

      const pagePath = encodeURIComponent(window.location.pathname);
      url += `&page_path=${pagePath}`;

      if (this.dataResponseId) {
        url += `&response_id=${this.dataResponseId}`;
      }

      if (this.dataVfHideQuestion == "true") {
        url += "&v_hide_question=true";
      }

      if (this.dataVfHideNextButton == "true") {
        url += "&v_hide_next_button=true";
      }

      if (this.dataMetadata) {
        const params = new URLSearchParams(this.dataMetadata).toString();
        url += `&${params}`;
      }

      // Add custom attributes to the URL
      Object.keys(this.customProps).forEach((prop) => {
        let propName = prop;
        // ignore voiceform specific props
        if (prop.startsWith("data-vf")) {
          return;
        }
        if (prop.startsWith("data-")) {
          propName = prop.slice(5); // Remove 'data-' prefix for custom attributes
        }
        // convert to snake_case
        propName = propName.replace(/-/g, "_").toLowerCase();
        url += `&${propName}=${encodeURIComponent(this.customProps[prop])}`;
      });

      return url;
    },

    style() {
      if (this.dataMode === "standard") {
        return "width: 100%; height: 100%";
      }
      return null;
    },
  },
};
</script>

<style module>
.root * {
  -webkit-tap-highlight-color: transparent;
  font-family: "Inter var", sans-serif;
}
</style>
