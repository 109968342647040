// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FullPageMode_page_Xpbko{border-style:none;bottom:0;height:100%;left:0;position:fixed;right:0;top:0}.FullPageMode_content__iPbO,.FullPageMode_page_Xpbko{display:flex;width:100%}.FullPageMode_content__iPbO{align-items:stretch}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "FullPageMode_page_Xpbko",
	"content": "FullPageMode_content__iPbO"
};
export default ___CSS_LOADER_EXPORT___;
