import defaultConfig from "@/defaultConfig";
import { createStore } from "vuex";

const getConfigFromElement = (element) => {
  return {
    ...defaultConfig,
    height: element.getAttribute("data-vf-height") || defaultConfig.height,
    width: element.getAttribute("data-vf-width") || defaultConfig.width,
    button: {
      ...defaultConfig.button,
      label:
        element.getAttribute("data-vf-btn-label") || defaultConfig.button.label,
      color:
        element.getAttribute("data-vf-btn-color") || defaultConfig.button.color,
      style:
        element.getAttribute("data-vf-btn-style") || defaultConfig.button.style,
      icon:
        element.getAttribute("data-vf-btn-icon") || defaultConfig.button.icon,
      size:
        element.getAttribute("data-vf-btn-size") || defaultConfig.button.size,
      borderRadius:
        element.getAttribute("data-vf-btn-border-radius") ||
        defaultConfig.button.borderRadius,
      fontSize:
        element.getAttribute("data-vf-btn-fonts-size") ||
        defaultConfig.button.fontSize,
      iconOnly:
        element.getAttribute("data-vf-btn-icon-only") === "true" ||
        defaultConfig.button.iconOnly,
    },
  };
};

const initStore = (element = window.document.currentScript) => {
  const config = getConfigFromElement(element);

  const state = {
    config,
  };

  const getters = {
    config(state) {
      return state.config;
    },
    buttonConfig(state) {
      return state.config.button;
    },
  };

  return createStore({
    state,
    getters,
  });
};

export default initStore;
