// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StandardMode_page_HOUs_{display:flex;height:100%;max-width:100%;position:relative;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "StandardMode_page_HOUs_"
};
export default ___CSS_LOADER_EXPORT___;
