<template>
  <Popover
    v-slot="{ open }"
    class="vf-fixed vf-right-4 vf-bottom-6 sm:vf-right-10 sm:vf-bottom-10"
  >
    <PopoverButton as="div">
      <Transition
        enter-from-class="vf-translate-y-1 vf-opacity-0"
        enter-active-class="vf-transition vf-duration-200 ease-out"
        enter-to-class="vf-translate-y-0 vf-opacity-100"
        leave-from-class="vf-translate-y-0 vf-opacity-100"
        leave-active-class="vf-transition vf-duration-150 ease-in vf-hidden"
        leave-to-class="vf-translate-y-1 vf-opacity-0"
      >
        <button
          v-if="open"
          role="button"
          :class="$style.closeButton"
          :style="closeButtonStyles"
        >
          <XMarkIcon class="vf-h-6 vf-w-6" />
        </button>
        <ActionButton v-else class="vf-shadow-xl"></ActionButton>
      </Transition>
    </PopoverButton>
    <Transition
      enter-from-class="vf-translate-y-1 vf-opacity-0"
      enter-active-class="vf-transition vf-duration-200 ease-out"
      enter-to-class="vf-translate-y-0 vf-opacity-100"
      leave-from-class="vf-translate-y-0 vf-opacity-100"
      leave-active-class="vf-transition vf-duration-150 ease-in"
      leave-to-class="vf-translate-y-1 vf-opacity-0"
    >
      <PopoverPanel
        :focus="false"
        v-if="open"
        :unmount="false"
        :class="$style.panel"
      >
        <slot></slot>
      </PopoverPanel>
    </Transition>
  </Popover>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { useStore } from "vuex";
import { computed } from "vue";
import ActionButton from "./ActionButton.vue";
import invertColor, { pSBC, getHoverColor } from "@/utils/invertColor";

const store = useStore();
const buttonConfig = computed(() => store.getters.buttonConfig);
const closeButtonStyles = computed(() => {
  const styles = {};

  if (buttonConfig.value.style === "outlined") {
    styles.backgroundColor = pSBC(0.8, buttonConfig.value.color);
    styles.border = `solid ${buttonConfig.value.color} 1px`;
    styles.color = buttonConfig.value.color;
  } else {
    styles.backgroundColor = buttonConfig.value.color;
    styles.color = invertColor(buttonConfig.value.color, true);
  }
  styles["--vf-btn-hover-color"] = getHoverColor(styles.backgroundColor);
  return styles;
});
</script>

<style module>
.panel {
  @apply vf-absolute vf-z-10 vf-rounded-xl vf-overflow-hidden vf-bg-gray-50 vf-bottom-16 vf-right-0 vf-w-full vf-ml-6 vf-shadow-md;
  height: calc(100vh - 6rem);
  width: calc(100vw - 2rem);

  @media (min-width: 640px) {
    width: 370px;
    height: 600px;
  }
}
.closeButton {
  @apply vf-inline-flex vf-items-center vf-text-black vf-justify-center vf-rounded-full  vf-border-none vf-transition-all vf-cursor-pointer focus-within:vf-outline-none vf-bg-gray-200 vf-h-10 vf-w-10 vf-shadow-xl;
}
.closeButton:hover {
  background-color: var(--vf-btn-hover-color) !important;
}
</style>
