// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SideDrawer_actionButton_kxwSW{--tw-bg-opacity:1;--tw-text-opacity:1;--tw-shadow:0 1px 2px 0 rgba(0,0,0,.05);--tw-shadow-colored:0 1px 2px 0 var(--tw-shadow-color);align-items:center;background-color:rgb(79 70 229/var(--tw-bg-opacity));border-color:transparent;border-radius:9999px;border-width:1px;box-shadow:var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);color:rgb(255 255 255/var(--tw-text-opacity));cursor:pointer;display:inline-flex;padding:4px}.SideDrawer_actionButton_kxwSW:hover{--tw-bg-opacity:1;background-color:rgb(67 56 202/var(--tw-bg-opacity))}.SideDrawer_actionButton_kxwSW:focus-visible{--tw-shadow:0 0 #0000;--tw-shadow-colored:0 0 #0000;box-shadow:var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);outline:2px solid transparent;outline-offset:2px}.SideDrawer_closeButton_JTE6y{--tw-text-opacity:1;--tw-shadow:0 0 #0000;--tw-shadow-colored:0 0 #0000;background-color:transparent;border-radius:6px;box-shadow:var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);color:rgb(55 65 81/var(--tw-text-opacity));cursor:pointer}.SideDrawer_closeButton_JTE6y:hover{--tw-text-opacity:1;color:rgb(107 114 128/var(--tw-text-opacity))}@media (min-width:640px){.SideDrawer_closeButton_JTE6y{--tw-text-opacity:1;color:rgb(243 244 246/var(--tw-text-opacity))}.SideDrawer_closeButton_JTE6y:hover{--tw-text-opacity:1;color:rgb(255 255 255/var(--tw-text-opacity))}}.SideDrawer_closeButton_JTE6y:focus-visible{--tw-shadow:0 0 #0000;--tw-shadow-colored:0 0 #0000;box-shadow:var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);outline:2px solid transparent;outline-offset:2px}.SideDrawer_closeButton_JTE6y{-webkit-tap-highlight-color:transparent;border:unset;padding:unset}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionButton": "SideDrawer_actionButton_kxwSW",
	"closeButton": "SideDrawer_closeButton_JTE6y"
};
export default ___CSS_LOADER_EXPORT___;
