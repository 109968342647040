<template>
  <button
    @click="$emit('open')"
    type="button"
    :class="classes"
    :style="buttonStyles"
  >
    <div
      v-if="buttonConfig.icon"
      :class="iconClasses"
      :style="`background-image: url(${buttonConfig.icon})`"
    ></div>
    {{ buttonLabel }}
  </button>
</template>

<script setup>
import { computed, useCssModule } from "vue";
import { useStore } from "vuex";
import invertColor, { pSBC, getHoverColor } from "@/utils/invertColor";

const props = defineProps(["class"]);

const store = useStore();
const $styles = useCssModule();
const buttonConfig = computed(() => store.getters.buttonConfig);

const buttonLabel = computed(() => {
  if (buttonConfig.value.iconOnly) return "";
  return buttonConfig.value.label || "Speak up";
});

const buttonStyles = computed(() => {
  const styles = {};
  if (buttonConfig.value.style === "filled") {
    styles.backgroundColor = buttonConfig.value.color;
    styles.color = invertColor(buttonConfig.value.color, true);
    styles["--vf-btn-hover-color"] = getHoverColor(styles.backgroundColor);
  }
  if (buttonConfig.value.style === "outlined") {
    styles.backgroundColor = pSBC(0.8, buttonConfig.value.color);
    styles.border = `solid ${buttonConfig.value.color} 1px`;
    styles.color = buttonConfig.value.color;
    styles["--vf-btn-hover-color"] = getHoverColor(styles.backgroundColor);
  }
  if (buttonConfig.value.style === "text") {
    styles.backgroundColor = "transparent";
    styles.color = buttonConfig.value.color;
  }
  if (!buttonConfig.value.iconOnly) {
    styles["border-radius"] = buttonConfig.value.borderRadius;
  }
  styles.fontSize = buttonConfig.value.fontSize;
  return styles;
});

const classes = computed(() => {
  return [
    $styles.buttonBase,
    {
      "vf-rounded-full  vf-p-0 vf-items-center vf-justify-center":
        buttonConfig.value.iconOnly,
    },
    {
      "vf-py-1 vf-px-2 vf-text-sm": buttonConfig.value.size === "small",
    },
    {
      "vf-py-3 vf-px-4 vf-text-lg": buttonConfig.value.size === "large",
    },
    {
      "vf-h-10 vf-w-10":
        buttonConfig.value.iconOnly && buttonConfig.value.size === "medium",
    },
    {
      "vf-h-8 vf-w-8":
        buttonConfig.value.iconOnly && buttonConfig.value.size === "small",
    },
    {
      "vf-h-14 vf-w-14":
        buttonConfig.value.iconOnly && buttonConfig.value.size === "large",
    },
    {
      "vf-shadow-none hover:vf-opacity-70": buttonConfig.value.style === "text",
    },
    props.class,
  ];
});
const iconClasses = computed(() => {
  return [
    $styles.iconBase,
    {
      "vf-mr-0": buttonConfig.value.iconOnly,
      "vf-h-8 vf-w-8": buttonConfig.value.size === "large",
      "vf-h-4 vf-w-4": buttonConfig.value.size === "small",
    },
  ];
});
</script>

<style module>
.iconBase {
  @apply vf-mr-2 vf-bg-no-repeat vf-bg-contain vf-bg-center vf-h-6 vf-w-6;
}
.buttonBase {
  @apply vf-rounded vf-inline-flex vf-items-center vf-border vf-border-transparent vf-py-2 vf-px-3  vf-shadow-sm vf-cursor-pointer vf-text-sm vf-font-medium;
}
.buttonBase:hover {
  background-color: var(--vf-btn-hover-color) !important;
}
</style>
