<template>
  <div class="vf-relative vf-w-full" style="max-width: 700px">
    <div
      v-if="loading"
      class="vf-inset-0 vf-flex vf-flex-col vf-animate-pulse vf-py-2 vf-cursor-progress"
    >
      <!-- Skeleton loader -->
      <div class="vf-bg-gray-200 vf-h-7 vf-w-2/12 vf-mb-4"></div>
      <div class="vf-bg-gray-200 vf-h-4 vf-w-11/12 vf-mb-4"></div>
      <div class="vf-bg-gray-200 vf-h-4 vf-w-7/12 vf-mb-4"></div>
      <div class="vf-bg-gray-200 vf-h-4 vf-w-8/12 vf-mb-4"></div>
      <div class="vf-bg-gray-200 vf-h-4 vf-w-5/12 vf-mb-4"></div>
    </div>
    <iframe
      :src="src"
      allow="microphone; camera"
      title="Voice powered form"
      :class="[$style.surveyFrame, { 'vf-hidden': loading }]"
      :style="{ height: `${iframeHeight}px` }"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      iframeHeight: 0,
    };
  },
  props: ["src", "survey", "style", "questionId"],
  methods: {
    handleIframeMessage(event) {
      if (
        event.data.type === "voiceform.questionReady" &&
        // in case its an old question id an numeric
        event.data.questionId == this.questionId
      ) {
        this.loading = false;
      }

      if (
        event.data.type === "voiceform.contentHeight" &&
        // in case its an old question id an numeric
        event.data.questionId == this.questionId
      ) {
        this.iframeHeight = event.data.height || 0;
      }
    },
  },
  mounted() {
    window.addEventListener("message", this.handleIframeMessage);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.handleIframeMessage);
  },
};
</script>

<style module>
.surveyFrame {
  display: flex;
  border: 0px;
  width: 100%;
  flex-grow: 1;
}
</style>
