<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog
      as="div"
      class="vf-relative vf-z-10"
      @close="$emit('update:modelValue', false)"
    >
      <TransitionChild
        as="template"
        enter="ease-in-out vf-duration-300"
        enter-from="vf-opacity-0"
        enter-to="vf-opacity-100"
        leave="ease-in-out vf-duration-300"
        leave-from="vf-opacity-100"
        leave-to="vf-opacity-0"
      >
        <div
          class="vf-fixed vf-inset-0 vf-bg-gray-500 vf-bg-opacity-75 vf-transition-opacity"
        />
      </TransitionChild>

      <div class="vf-fixed vf-inset-0 vf-overflow-hidden">
        <div class="vf-absolute vf-inset-0 vf-overflow-hidden">
          <div
            class="vf-pointer-events-none vf-fixed vf-inset-y-0 vf-right-0 vf-flex vf-max-w-full vf-pl-10"
          >
            <TransitionChild
              as="template"
              enter="vf-transform vf-transition ease-in-out vf-duration-300"
              enter-from="vf-translate-y-full vf-translate-x-0 sm:vf-translate-y-0 sm:vf-translate-x-full"
              enter-to="vf-translate-y-0 vf-translate-x-0"
              leave="vf-transform vf-transition ease-in-out vf-duration-300"
              leave-from="vf-translate-y-0 vf-translate-x-0"
              leave-to="vf-translate-y-full vf-translate-x-0 sm:vf-translate-y-0 sm:vf-translate-x-full"
            >
              <DialogPanel
                class="vf-flex vf-flex-col vf-justify-end vf-pointer-events-auto vf-relative vf-w-screen sm:vf-max-w-md"
              >
                <TransitionChild
                  as="template"
                  enter="ease-in-out vf-duration-300"
                  enter-from="vf-opacity-0"
                  enter-to="vf-opacity-100"
                  leave="ease-in-out vf-duration-300"
                  leave-from="vf-opacity-100"
                  leave-to="vf-opacity-0"
                >
                  <div
                    class="vf-hidden sm:vf-block vf-absolute vf-top-0 vf-left-0 -vf-ml-8 flex vf-pt-4 vf-pr-2 sm:-vf-ml-10 sm:vf-pr-4"
                  >
                    <button
                      :class="$style.closeButton"
                      @click="$emit('update:modelValue', false)"
                    >
                      <span class="vf-sr-only">Close panel</span>
                      <XMarkIcon class="vf-h-6 vf-w-6" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <div
                  class="vf-flex vf-h-full vf-flex-col sm:vf-bg-white vf-shadow-xl"
                >
                  <div class="sm:vf-hidden vf-flex vf-justify-end vf-p-4">
                    <button
                      :class="[
                        $style.closeButton,
                        'vf-inline-flex vf-items-center vf-rounded-full vf-border vf-border-transparent vf-p-1 vf-bg-gray-100 vf-text-black hover:vf-bg-gray-50 vf-cursor-pointer',
                      ]"
                      @click="$emit('update:modelValue', false)"
                    >
                      <span class="vf-sr-only">Close panel</span>
                      <XMarkIcon class="vf-h-6 vf-w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div
                    class="vf-relative vf-flex-1 vf-bg-white vf-rounded-t-2xl sm:vf-rounded-none vf-overflow-hidden vf-h-full"
                  >
                    <slot></slot>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
export default {
  name: "SideDrawer",
  props: ["modelValue"],
  emits: ["update:modelValue"],
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    XMarkIcon,
  },
};
</script>

<style module>
.actionButton {
  @apply vf-inline-flex vf-items-center vf-rounded-full vf-border vf-border-transparent vf-bg-indigo-600 vf-p-1 vf-text-white vf-shadow-sm hover:vf-bg-indigo-700 vf-cursor-pointer focus-visible:vf-outline-none focus-visible:vf-shadow-none;
}
.closeButton {
  @apply vf-rounded-md vf-text-gray-700 sm:vf-text-gray-100 hover:vf-text-gray-500 sm:hover:vf-text-white vf-bg-transparent vf-shadow-none vf-cursor-pointer;
  @apply focus-visible:vf-outline-none focus-visible:vf-shadow-none;
  padding: unset;
  border: unset;
  -webkit-tap-highlight-color: transparent;
}
</style>
