// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActionButton_iconBase_iY_Rv{background-position:50%;background-repeat:no-repeat;background-size:contain;height:24px;margin-right:8px;width:24px}.ActionButton_buttonBase_y7JJy{--tw-shadow:0 1px 2px 0 rgba(0,0,0,.05);--tw-shadow-colored:0 1px 2px 0 var(--tw-shadow-color);align-items:center;border-color:transparent;border-radius:4px;border-width:1px;box-shadow:var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);cursor:pointer;display:inline-flex;font-size:14px;font-weight:500;line-height:20px;padding:8px 12px}.ActionButton_buttonBase_y7JJy:hover{background-color:var(--vf-btn-hover-color)!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconBase": "ActionButton_iconBase_iY_Rv",
	"buttonBase": "ActionButton_buttonBase_y7JJy"
};
export default ___CSS_LOADER_EXPORT___;
