// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SideTabMode_iconBase_SkMtj{--tw-rotate:90deg;background-position:50%;background-repeat:no-repeat;background-size:contain;height:24px;margin-right:8px;width:24px}.SideTabMode_iconBase_SkMtj,.SideTabMode_sideTabBase_vDcyv{transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))}.SideTabMode_sideTabBase_vDcyv{--tw-rotate:-90deg;--tw-shadow:0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -4px rgba(0,0,0,.1);--tw-shadow-colored:0 10px 15px -3px var(--tw-shadow-color),0 4px 6px -4px var(--tw-shadow-color);align-items:center;border-style:none;border-top-left-radius:12px;border-top-right-radius:12px;box-shadow:var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);cursor:pointer;display:inline-flex;font-size:18px;font-weight:500;line-height:28px;padding:8px 16px;position:fixed;right:0;top:50%;z-index:10}.SideTabMode_sideTabBase_vDcyv:focus{opacity:.95}.SideTabMode_sideTabBase_vDcyv{transform:translateX(50%) rotate(270deg);transform-origin:center bottom}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconBase": "SideTabMode_iconBase_SkMtj",
	"sideTabBase": "SideTabMode_sideTabBase_vDcyv"
};
export default ___CSS_LOADER_EXPORT___;
