<template>
  <TransitionRoot as="template" :show="true">
    <Transition
      :appear="true"
      enter-from-class="vf-translate-x-full vf-opacity-0"
      enter-active-class="vf-transition vf-duration-200 vf-delay-300 ease-out"
      enter-to-class="vf-translate-x-1/2 vf-opacity-100"
      leave-from-class="vf-translate-x-1/2 vf-opacity-100"
      leave-active-class="vf-transition vf-duration-150 ease-in"
      leave-to-class="vf-translate-x-full vf-opacity-0"
    >
      <button
        @click="open = !open"
        type="button"
        :class="classes"
        :style="buttonStyles"
      >
        <div
          v-if="buttonConfig.icon"
          :class="iconClasses"
          :style="`background-image: url(${buttonConfig.icon})`"
        ></div>
        {{ buttonConfig.label || "Speak up" }}
      </button>
    </Transition>
  </TransitionRoot>
  <SideDrawer v-model="open"><slot></slot></SideDrawer>
</template>

<script setup>
import { computed, useCssModule } from "vue";
import { TransitionRoot } from "@headlessui/vue";
import { ref } from "vue";
import SideDrawer from "./SideDrawer.vue";
import { useStore } from "vuex";
import invertColor, { pSBC } from "@/utils/invertColor";

const store = useStore();
const $styles = useCssModule();
const buttonConfig = computed(() => store.getters.buttonConfig);

const buttonStyles = computed(() => {
  const styles = {};
  if (buttonConfig.value.style === "filled") {
    styles.backgroundColor = buttonConfig.value.color;
    styles.color = invertColor(buttonConfig.value.color, true);
  }
  if (buttonConfig.value.style === "outlined") {
    styles.backgroundColor = pSBC(0.8, buttonConfig.value.color);
    styles.border = `solid ${buttonConfig.value.color} 2px`;
    styles.borderBottomWidth = 0;
    styles.color = buttonConfig.value.color;
  }
  return styles;
});

const classes = computed(() => {
  return [
    $styles.sideTabBase,
    {
      "vf-text-xl": buttonConfig.value.size === "large",
      "vf-text-sm vf-px-2 vf-py-1": buttonConfig.value.size === "small",
    },
  ];
});
const iconClasses = computed(() => {
  return [
    $styles.iconBase,
    {
      "vf-mr-3 vf-h-8 vf-w-8": buttonConfig.value.size === "large",
      "vf-mr-2 vf-h-4 vf-w-4": buttonConfig.value.size === "small",
    },
  ];
});

const open = ref(false);
</script>

<style module>
.iconBase {
  @apply vf-mr-2 vf-bg-no-repeat vf-bg-contain vf-bg-center vf-h-6 vf-w-6 vf-rotate-90;
}
.sideTabBase {
  @apply focus:vf-opacity-95 vf-font-medium vf-inline-flex vf-items-center vf-text-lg vf-z-10 vf-py-2 vf-px-4 vf-fixed vf-right-0 vf-top-1/2 -vf-rotate-90 vf-rounded-t-xl vf-shadow-lg vf-border-none vf-cursor-pointer;
  transform-origin: center bottom;
  transform: translateX(50%) rotate(270deg);
}
</style>
